import "./App.scss";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { ROUTES } from "./routes/constants";
import { getAuth, getUser } from "./containers/Auth/store/selectors";
import useAuth from "./utils/useAuth";
import useResetOverflowOnRouteChange from "./utils/useResetOverflowOnRouteChange";

import Landing from "./containers/Landing";
import ResetPassword from "./containers/Auth/ResetPassword";
import Dashboard from "./containers/Dashboard";
import DownloadPdf from "./containers/DownloadPdf";
import DownloadPdfTest from "./containers/DownloadPdf/DownloadPdfTest";
import TYP from "./containers/TYP";
import { PrivateRoute } from "./routes/containers/PrivateRoute";
import { PublicRoute } from "./routes/containers/PublicRoute";
import { AdminRoute } from "./routes/containers/AdminRoute";

import Error from "./shared/Error/Error";
import Loader from "./shared/Loader/Loader";
import { ToastContainer } from "react-toastify";
import { AnimatePresence } from "framer-motion";
import ReactGA from "react-ga4";
ReactGA.initialize("G-NP3312QJ25");

function App() {
  const location = useLocation();
  const { isUserLoading } = useAuth();
  const { isAdmin } = useSelector(getUser());
  const isAuthenticated = useSelector(getAuth());
  // console.log(isAdmin, "isAdmin");

  // Reset overflow on route change
  useResetOverflowOnRouteChange();

  return (
    !isUserLoading && (
      <section className="App">
        <Error />
        <Loader />
        <ToastContainer />

        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Landing />} />
            <Route
              path={ROUTES.DASHBOARD}
              element={
                <PrivateRoute
                  component={Dashboard}
                  isAuthenticated={isAuthenticated}
                  title="Dashboard"
                  route={ROUTES.DASHBOARD}
                />
              }
            />
            <Route
              path={`${ROUTES.DOWNLOAD_REPORT}`}
              element={<DownloadPdf />}
            />
            <Route
              path={`${ROUTES.DOWNLOAD_TEST}`}
              element={
                <AdminRoute component={DownloadPdfTest} isAdmin={isAdmin} />
              }
            />
            <Route
              path={ROUTES.TYP}
              element={
                <PrivateRoute
                  component={TYP}
                  isAuthenticated={isAuthenticated}
                  title="Congratulazioni!"
                  route={ROUTES.TYP}
                />
              }
            />
            <Route
              path={`${ROUTES.RESET_PASSWORD}/:token`}
              element={
                <PublicRoute
                  component={ResetPassword}
                  isAuthenticated={isAuthenticated}
                  restricted={true}
                  title="Reimposta Password"
                  route={ROUTES.RESET_PASSWORD}
                />
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </AnimatePresence>
      </section>
    )
  );
}

export default App;
