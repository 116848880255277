import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import ReactGA from "react-ga4";

import Navigation from "./Navigation";
import Hero from "./Hero";
import TownInfo from "./TownInfo";
import Why from "./Why";
import AgencyCta from "./AgencyCta";
import Reviews from "./Reviews";
import Footer from "./Footer";
import Auth from "./Auth";

import useCheckBakeca from "../../utils/useCheckBakeca";
import useNoScroll from "../../utils/useNoScroll";
import useMotionScrollDirection from "../../utils/useMotionScrollDirection";

import { getTypeModal } from "./store/selectors";

const Landing = () => {
  const typeModal = useSelector(getTypeModal());
  const { isFromBakeca, hasMinimumInfo, isBakecaLoading } = useCheckBakeca();
  // console.log(isFromBakeca, "isFromBakeca");
  // console.log(hasMinimumInfo, "hasMinimumInfo");
  useMotionScrollDirection();

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Landing page",
    });
  }, []);

  // Refs
  const townInfoRef = useRef(null);
  const whyRef = useRef(null);
  const footerRef = useRef(null);

  // ADJUST BODY SCROLL WHEN MODALS ARE OPEN
  useNoScroll(typeModal.type);

  return (
    !isBakecaLoading && (
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: "easeOut",
          },
        }}
        transition={{
          duration: 0.5,
          ease: "easeIn",
        }}
      >
        <main>
          <Navigation
            townInfoRef={townInfoRef}
            whyRef={whyRef}
            footerRef={footerRef}
            hasMinimumInfo={hasMinimumInfo}
          />
          <Hero hasMinimumInfo={hasMinimumInfo} isFromBakeca={isFromBakeca} />
          <TownInfo townInfoRef={townInfoRef} hasMinimumInfo={hasMinimumInfo} />
          <Why whyRef={whyRef} />
          <AgencyCta />
          <Reviews />
          <Footer footerRef={footerRef} />
          <Auth hasMinimumInfo={hasMinimumInfo} />
        </main>
      </motion.section>
    )
  );
};

export default Landing;
