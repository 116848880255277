import styles from "./Card.module.scss";
import ReactGA from "react-ga4";
import clx from "classnames";
import GraphicImg from "../../../../../assets/svgs/qualita-dati.svg";

import { useDispatch } from "react-redux";
import { setTypeModal } from "../../../store/actions";

const Card = ({ cardTitle, isBuy = false, gap, sampleImg, children }) => {
  const dispatch = useDispatch();

  return (
    <div
      className={clx(styles.card, { [styles.isBuy]: isBuy })}
      style={{ gap }}
      onClick={() => {
        if (isBuy) {
          ReactGA.event({
            category: "Open Offer Modal",
            action: "Open Offer Modal",
            label: "Open Offer Modal",
          });
          dispatch(setTypeModal({ type: "card", isBuy: false }));
        }
      }}
    >
      {!isBuy ? (
        <>
          <div className={styles.card_title}>{cardTitle}</div>
          <div className={styles.card_img}>
            {children || (
              <img
                src={sampleImg}
                style={{ borderRadius: "15px" }}
                alt="placeholder-blurred-img"
              />
            )}
          </div>
        </>
      ) : (
        <>
          <h4 className={styles.card_buy__title}>
            Scopri tutti i dati che offriamo
          </h4>
          <img
            src={GraphicImg}
            alt="placeholder-blurred-img"
            className={styles.card__graphic}
          />
        </>
      )}
    </div>
  );
};

export default Card;
