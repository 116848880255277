import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import clx from "classnames";
import styles from "./Nav.module.scss";
import BakecaHpLogo from "../../../../assets/svgs/bakeca-logo-new.svg";
import { ReactComponent as Dashboard } from "../../../../assets/svgs/dashboard.svg";
import { ReactComponent as Login } from "../../../../assets/svgs/enter.svg";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import alertToast from "../../../AlertToast";

import { ROUTES } from "../../../../routes/constants";

import useScrollPosition from "../../../../utils/useScrollPosition";
import scrollToSection from "../../../../utils/scrollToSection";

import { setTypeModal } from "../../store/actions";

const Nav = ({
  townInfoRef,
  whyRef,
  footerRef,
  isUserLoggedin,
  hasMinimumInfo,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(townInfoRef, "townInfoRef");

  // State needed to toggle form header display on scrolling
  const [scrollPosition, setScrollPosition] = useState(0);
  // Function setting scroll position state (needed for navbar color style changes)
  useScrollPosition(setScrollPosition);

  return (
    <div
      className={clx(styles.nav, "navbar-main", {
        [styles.logo_shadow]: scrollPosition > 60,
      })}
    >
      {/* Logo */}
      <div className={styles.nav_logo}>
        <img src={BakecaHpLogo} alt="logo" />
      </div>

      {/* Links */}
      <ul className={styles.nav_links}>
        <li
          tabIndex="0"
          onClick={() => scrollToSection(townInfoRef)}
          onKeyPress={(e) => e.key === "Enter" && scrollToSection(townInfoRef)}
        >
          Anteprima dell'analisi
        </li>
        <li
          tabIndex="0"
          onClick={() => scrollToSection(whyRef)}
          onKeyPress={(e) => e.key === "Enter" && scrollToSection(whyRef)}
        >
          Perché Houseplus
        </li>
        <li
          tabIndex="0"
          onClick={() => scrollToSection(footerRef)}
          onKeyPress={(e) => e.key === "Enter" && scrollToSection(footerRef)}
        >
          Contattaci
        </li>
      </ul>
      {/* )} */}

      {/* Ctas */}
      <div className={styles.nav_ctas}>
        <div className={styles.nav_ctas_login}>
          <SecondaryButton
            text={isUserLoggedin ? "Dashboard" : "Accedi"}
            leftIconSrc={isUserLoggedin ? Dashboard : Login}
            isReactIcon={true}
            viewBox={isUserLoggedin ? "0 -1 18 18" : "0 -1 20 20"}
            onClick={() => {
              if (isUserLoggedin) {
                navigate(ROUTES.DASHBOARD);
              } else {
                dispatch(setTypeModal({ type: "login", isBuy: false }));
              }
            }}
            className={!isUserLoggedin && styles.dashboard_button}
          />
        </div>
        <div className={styles.nav_ctas_buy_report}>
          <PrimaryButton
            text="Acquista la tua analisi"
            onClick={() => {
              ReactGA.event({
                category: "Buy report Modal",
                action: "Buy report Modal",
                label: "Buy report Modal",
              });

              if (hasMinimumInfo) {
                if (isUserLoggedin) {
                  dispatch(setTypeModal({ type: "buy", isBuy: true }));
                } else {
                  dispatch(setTypeModal({ type: "signup", isBuy: true }));
                }
              } else {
                alertToast(
                  "warning",
                  "Dati insufficienti",
                  "L'immobile selezionato non ha abbastanza informazioni per generare un report."
                );
              }
            }}
            disabled={!hasMinimumInfo}
          />
        </div>
      </div>
    </div>
  );
};

export default Nav;
